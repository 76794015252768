<template>
  <div>
    <div id="signature-pad">
      <canvas id="canvas" class="h-13rem"></canvas>
    </div>
    <div class="grid" style="margin: 1px">
      <div class="p-inputgroup mt-2" style="width: 50%">
        <small
          class="p-inputgroup-addon font-semibold general-label-color"
          :style="{ width: '104px' }"
          >Signed By</small
        >
        <InputText
          v-model="receivedByInput"
          id="receivedBy"
          :disabled="readOnly"
        />
      </div>
      <div class="p-inputgroup mt-2" style="width: 50%">
        <small
          class="p-inputgroup-addon font-semibold general-label-color single-field mx-1"
          :style="{ width: '150px', borderRight: '1px solid #ced4da' }"
          >Date: {{ receivedDateInput }}</small
        >
        <small
          class="p-inputgroup-addon font-semibold general-label-color single-field mx-1"
          :style="{ width: '150px' }"
          >Time: {{ receivedDateTimeInput }}</small
        >
      </div>
    </div>
    <br />
    <Button
      v-if="!readOnly"
      label="Clear"
      @click="clear"
      style="margin-bottom: 0.5em"
    />
    <Button
      v-if="!readOnly"
      label="Save"
      @click="save"
      style="margin-left: 0.5em; margin-bottom: 0.5em"
    />
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Signaturepad from "signature_pad";
import Utils from "@/utility/utils";
import { mapActions } from "vuex";

export default defineComponent({
  name: "SignaturePad",
  emits: ["save", "clear"],
  data() {
    return {
      dataUrl: null,
      signaturePad: null,
      receivedByInput: this.receivedBy,
      receivedDateInput: this.receivedDate,
      receivedDateTimeInput: this.receivedDateTime,
    };
  },
  updated() {
    this.updateData();
  },
  mounted() {
    var wrapper = document.getElementById("signature-pad");
    var canvas = wrapper.querySelector("#canvas");
    this.signaturePad = new Signaturepad(canvas, {
      // It's Necessary to use an opaque color when saving image as JPEG;
      // this option can be omitted if only saving as PNG or SVG
      backgroundColor: "rgb(230,230,230)",
      penColor: "rgb(11,83,105)",
    });
    canvas.style.width = "100%";
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear();

    if (this.readOnly) {
      this.signaturePad.off();
    } else {
      if (!this.receivedDateInput) {
        this.receivedDateInput = Utils.formatDate(new Date());
      }

      if (!this.receivedDateTimeInput) {
        this.receivedDateTimeInput = Utils.formatTime(new Date());
      }
    }

    this.updateData();
  },
  components: {
    Button,
    InputText,
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    save() {
      // TODO - figure out why the signature pad can't add notifications with dialog
      if (this.signaturePad.isEmpty()) {
        this.addNotification({
          type: "error",
          message: "Please provide a signature.",
        });
      } else if (!this.receivedByInput) {
        this.addNotification({
          type: "error",
          message: "Please provide a signed by name.",
        });
      } else {
        this.dataUrl = this.signaturePad.toDataURL("image/jpeg");
        const [meta, data] = this.dataUrl.split(",");

        const date = new Date();
        this.$emit("save", {
          signatureType: meta,
          signature: Utils.zipBase64Encode(data),
          receivedBy: this.receivedByInput,
          receivedDate: this.receivedDateInput || Utils.formatDate(date),
          receivedTime: Utils.formatDateToRoverTime(
            date,
            this.receivedDateTimeInput,
          ),
        });
      }
    },
    clear() {
      this.receivedDateTimeInput = Utils.formatTime(new Date());
      this.receivedByInput = "";
      this.signaturePad.clear();
      this.$emit("clear");
    },
    updateData() {
      this.$nextTick(() => {
        const signatureValue = Utils.base64DecodeUnzip(this.signature);
        const signature = `${this.signatureType},${signatureValue}`;
        this.signaturePad.fromDataURL(signature);

        if (this.readOnly) {
          this.receivedByInput = this.receivedBy;
          this.receivedDateInput = this.receivedDate;
          this.receivedDateTimeInput = this.receivedDateTime;
        }
      });
    },
  },
  props: {
    signature: String,
    signatureType: String,
    receivedBy: String,
    receivedDate: String,
    receivedDateTime: String,
    readOnly: Boolean,
  },
});
</script>
