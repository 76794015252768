import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid formgrid mt-2" }
const _hoisted_2 = { class: "grid col-12 p-0" }
const _hoisted_3 = {
  key: 0,
  class: "lg:col-5 col-12"
}
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { class: "lg:col-5 col-12" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { class: "lg:col-2 col-12" }
const _hoisted_8 = { class: "field-checkbox lg:mt-5 lg:mb-5" }
const _hoisted_9 = { class: "grid formgrid" }
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "lg:col-4 col-12" }
const _hoisted_12 = { class: "field" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "lg:col-4 col-12" }
const _hoisted_15 = { class: "field" }
const _hoisted_16 = { class: "lg:col-4 col-12" }
const _hoisted_17 = { class: "field" }
const _hoisted_18 = { class: "lg:col-4 col-12" }
const _hoisted_19 = { class: "field" }
const _hoisted_20 = { class: "lg:col-4 col-12" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = { class: "grid formgrid" }
const _hoisted_23 = { class: "grid" }
const _hoisted_24 = { class: "col-12 lg:col-6" }
const _hoisted_25 = { class: "grid" }
const _hoisted_26 = { for: "address" }
const _hoisted_27 = { class: "col-12 lg:col-6" }
const _hoisted_28 = { class: "grid" }
const _hoisted_29 = { class: "field lg:col-4 col-12" }
const _hoisted_30 = { class: "field lg:col-4 col-12" }
const _hoisted_31 = { class: "field lg:col-4 col-12" }
const _hoisted_32 = { class: "field col-12 mb-3" }
const _hoisted_33 = { class: "grid formgrid" }
const _hoisted_34 = { class: "grid" }
const _hoisted_35 = { class: "col-12 lg:col-6" }
const _hoisted_36 = { class: "grid mb-2" }
const _hoisted_37 = { class: "field col-12 lg:col-6" }
const _hoisted_38 = { class: "field col-12 lg:col-6" }
const _hoisted_39 = { class: "flex" }
const _hoisted_40 = { class: "grid mb-2" }
const _hoisted_41 = { class: "field col-12 lg:col-6" }
const _hoisted_42 = {
  key: 0,
  class: "p-error"
}
const _hoisted_43 = { class: "field col-12 lg:col-6" }
const _hoisted_44 = { class: "flex" }
const _hoisted_45 = { class: "col-12 lg:col-6" }
const _hoisted_46 = { class: "grid mb-2" }
const _hoisted_47 = { class: "field col-12" }
const _hoisted_48 = { class: "flex" }
const _hoisted_49 = {
  key: 0,
  class: "p-error"
}
const _hoisted_50 = { class: "grid mb-2" }
const _hoisted_51 = { class: "field col-12" }
const _hoisted_52 = { class: "flex" }
const _hoisted_53 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      header: "Contact Details",
      modal: true,
      class: "p-fluid",
      scrollable: true,
      draggable: false,
      scrollDirection: "both",
      style: { width: '720px' },
      "onUpdate:visible": _ctx.hideDialog
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.hideDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check",
          class: "p-button-text",
          loading: _ctx.isSubmitting,
          onClick: _ctx.saveCardInfo
        }, null, 8, ["loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.isNewContact)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", { for: "contact_id" }, "Id", -1)),
                    _createVNode(_component_InputText, {
                      id: "contact_id",
                      modelValue: _ctx.contactsinfo.contact_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactsinfo.contact_id) = $event)),
                      modelModifiers: { trim: true },
                      disabled: ""
                    }, null, 8, ["modelValue"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { for: "contact_type" }, "Type ", -1)),
                _createVNode(_component_Dropdown, {
                  id: "contact_type",
                  options: _ctx.getContactIdItems,
                  optionLabel: "description",
                  optionValue: "contact_id",
                  modelValue: _ctx.type,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                  editable: true
                }, null, 8, ["options", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Checkbox, {
                  id: "contact_portal",
                  binary: true,
                  modelValue: _ctx.portal,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.portal) = $event)),
                  ref: "checkbox"
                }, null, 8, ["modelValue"]),
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "contact_portal" }, "Portal", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[25] || (_cache[25] = _createElementVNode("h3", { class: "col-12" }, "Contact Name", -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "first_name" }, "First Name", -1)),
                _createVNode(_component_InputText, {
                  id: "first_name",
                  modelValue: _ctx.contactsinfo.first_name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactsinfo.first_name) = $event)),
                  modelModifiers: { trim: true },
                  ref: "first_name",
                  autofocus: "",
                  class: _normalizeClass({ 'p-invalid': _ctx.submitted && !_ctx.contactsinfo.first_name })
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted && !_ctx.contactsinfo.first_name)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_13, "Firstname is required."))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[21] || (_cache[21] = _createElementVNode("label", { for: "middle_name" }, "Middle Name", -1)),
                _createVNode(_component_InputText, {
                  id: "middle_name",
                  modelValue: _ctx.contactsinfo.middle_name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactsinfo.middle_name) = $event)),
                  modelModifiers: { trim: true }
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[22] || (_cache[22] = _createElementVNode("label", { for: "last_name" }, "Last Name", -1)),
                _createVNode(_component_InputText, {
                  id: "last_name",
                  modelValue: _ctx.contactsinfo.last_name,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactsinfo.last_name) = $event)),
                  modelModifiers: { trim: true }
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _cache[23] || (_cache[23] = _createElementVNode("label", { for: "salutation" }, " Salutation", -1)),
                _createVNode(_component_Dropdown, {
                  id: "salutation",
                  options: _ctx.contactSalutations,
                  modelValue: _ctx.contactsinfo.salutation,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contactsinfo.salutation) = $event))
                }, null, 8, ["options", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _cache[24] || (_cache[24] = _createElementVNode("label", { for: "job_title" }, "Title", -1)),
                _createVNode(_component_InputText, {
                  id: "job_title",
                  modelValue: _ctx.contactsinfo.job_title,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.contactsinfo.job_title) = $event)),
                  modelModifiers: { trim: true }
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[26] || (_cache[26] = _createElementVNode("h3", null, "Contact Address", -1)),
              _createElementVNode("div", _hoisted_25, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsinfo.address_items, (addressItem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "field col-12",
                    key: index
                  }, [
                    _createElementVNode("label", _hoisted_26, " Address Line " + _toDisplayString(index + 1), 1),
                    _createVNode(_component_InputText, {
                      id: "address",
                      modelValue: addressItem.address,
                      "onUpdate:modelValue": ($event: any) => ((addressItem.address) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _cache[31] || (_cache[31] = _createElementVNode("h3", null, " ", -1)),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[27] || (_cache[27] = _createElementVNode("label", { for: "city" }, "City", -1)),
                  _createVNode(_component_InputText, {
                    id: "city",
                    modelValue: _ctx.contactsinfo.city,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.contactsinfo.city) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _cache[28] || (_cache[28] = _createElementVNode("label", { for: "state" }, " State", -1)),
                  _createVNode(_component_InputText, {
                    id: "state",
                    modelValue: _ctx.contactsinfo.state,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.contactsinfo.state) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _cache[29] || (_cache[29] = _createElementVNode("label", { for: "zip" }, " Zip", -1)),
                  _createVNode(_component_InputText, {
                    id: "zip",
                    modelValue: _ctx.contactsinfo.zip,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.contactsinfo.zip) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _cache[30] || (_cache[30] = _createElementVNode("label", { for: "country" }, " Country", -1)),
                  _createVNode(_component_InputText, {
                    id: "country",
                    modelValue: _ctx.contactsinfo.country,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.contactsinfo.country) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _cache[36] || (_cache[36] = _createElementVNode("h3", null, "Contact Phones", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsinfo.telephone_items, (ele, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _cache[32] || (_cache[32] = _createElementVNode("label", { for: "telephone" }, "Telephone", -1)),
                      _createVNode(_component_InputMask, {
                        id: "telephone",
                        mask: "999-999-9999",
                        modelValue: ele.telephone,
                        "onUpdate:modelValue": ($event: any) => ((ele.telephone) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _cache[33] || (_cache[33] = _createElementVNode("label", { for: "telephone_ext" }, "Ext", -1)),
                      _createElementVNode("div", _hoisted_39, [
                        _createVNode(_component_InputText, {
                          id: "telephone_ext",
                          modelValue: ele.telephone_ext,
                          "onUpdate:modelValue": ($event: any) => ((ele.telephone_ext) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_Button, {
                          icon: "pi pi-minus",
                          class: "p-button-text",
                          onClick: ($event: any) => (_ctx.deleteField(ele, index))
                        }, null, 8, ["onClick"])
                      ])
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _cache[34] || (_cache[34] = _createElementVNode("label", { for: "telephone" }, "Telephone", -1)),
                  _createVNode(_component_InputMask, {
                    id: "telephone",
                    ref: "telephone",
                    modelValue: _ctx.telephone,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.telephone) = $event)),
                    mask: "999-999-9999",
                    class: _normalizeClass({
                    'p-invalid':
                      _ctx.isAddingField &&
                      _ctx.telephone &&
                      !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(_ctx.telephone) &&
                      !/^[0-9]{10}$/.test(_ctx.telephone),
                  })
                  }, null, 8, ["modelValue", "class"]),
                  (
                    _ctx.isAddingField &&
                    _ctx.telephone &&
                    !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(_ctx.telephone) &&
                    !/^[0-9]{10}$/.test(_ctx.telephone)
                  )
                    ? (_openBlock(), _createElementBlock("small", _hoisted_42, "Please enter a valid phone number."))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _cache[35] || (_cache[35] = _createElementVNode("label", { for: "telephone_ext" }, "Ext", -1)),
                  _createElementVNode("div", _hoisted_44, [
                    _createVNode(_component_InputText, {
                      id: "telephone_ext",
                      modelValue: _ctx.telephoneExt,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.telephoneExt) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-plus",
                      class: "p-button-text",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.pushPhoneDetails()))
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_45, [
              _cache[39] || (_cache[39] = _createElementVNode("h3", null, "Contact Emails", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsinfo.email_address_items, (ele, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _cache[37] || (_cache[37] = _createElementVNode("label", { for: "email_address" }, "Email Address", -1)),
                      _createElementVNode("div", _hoisted_48, [
                        _createVNode(_component_InputText, {
                          modelValue: ele.email_address,
                          "onUpdate:modelValue": ($event: any) => ((ele.email_address) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_Button, {
                          icon: "pi pi-minus",
                          class: "p-button-text",
                          onClick: ($event: any) => (_ctx.deleteField(ele, index)),
                          title: "Delete Email Field"
                        }, null, 8, ["onClick"])
                      ]),
                      (
                      _ctx.submitted &&
                      ele?.email_address &&
                      !/^\S+@\S+\.\S+$/.test(ele?.email_address)
                    )
                        ? (_openBlock(), _createElementBlock("small", _hoisted_49, "Please enter a valid email address."))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _cache[38] || (_cache[38] = _createElementVNode("label", { for: "email_address" }, "Email Address", -1)),
                  _createElementVNode("div", _hoisted_52, [
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.emailAddress,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.emailAddress) = $event)),
                      ref: "email_address",
                      class: _normalizeClass({
                      'p-invalid':
                        _ctx.isAddingField &&
                        _ctx.emailAddress &&
                        !/^\S+@\S+\.\S+$/.test(_ctx.emailAddress),
                    })
                    }, null, 8, ["modelValue", "class"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-plus",
                      class: "p-button-text",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.pushEmailAddress())),
                      title: "Add Email Field"
                    })
                  ]),
                  (
                    _ctx.isAddingField &&
                    _ctx.emailAddress &&
                    !/^\S+@\S+\.\S+$/.test(_ctx.emailAddress)
                  )
                    ? (_openBlock(), _createElementBlock("small", _hoisted_53, "Please enter a valid email address."))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ]))
}