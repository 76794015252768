
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Button from "primevue/button";

import Contact from "@/types/contact";
import Customer from "@/types/customer";
import { mapState } from "pinia";
import { useContactControlStore } from "@/store/data/controls/contact";

export default defineComponent({
  components: {
    Dialog,
    Dropdown,
    Checkbox,
    InputText,
    InputMask,
    Button,
  },
  props: {
    visible: Boolean,
    isNewContact: Boolean,
    index: Number,
    contactsInfoToEdit: Object,
    definedCustomerId: String,
  },
  emits: ["onDialogClose", "onContactSave"],
  data() {
    return {
      submitted: false,
      portal: false,
      type: "",
      emailAddress: "",
      telephone: "",
      telephoneExt: "",
      isAddingField: false,
      isSubmitting: false,
      contacts: [] as Array<Contact>,
      contactsinfo: { contact_id: "" } as Contact | Record<string, unknown>,
      contactSalutations: ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr."],
    };
  },
  async created() {
    const contactControlStore = useContactControlStore();
    await contactControlStore.fetchContactControl();
  },
  computed: {
    ...mapGetters({
      getCustomerMap: "customerInquiry/getCustomerMap",
      getCustomer: "customerInquiry/getCustomer",
      getContacts: "customerInquiry/getContacts",
      getClient: "session/getClient",
    }),
    ...mapState(useContactControlStore, {
      getContactIdItems: "getContactIdItems",
    }),
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.initializeDialog();
      }
    },
  },
  methods: {
    ...mapActions({
      addContact: "customerInquiry/addContact",
      updateContactFields: "customerInquiry/updateContactFields",
      addNotification: "notification/add",
    }),
    initializeDialog() {
      if (this.index) {
        this.contacts = this.getContacts(this.index) as Array<Contact>;
      }
      if (this.isNewContact) {
        this.createnewContact();
      } else {
        this.editcontact();
      }
    },
    createnewContact() {
      if (this.contactsInfoToEdit?.email_address_items) {
        this.contactsinfo.email_address_items = JSON.parse(
          JSON.stringify(this.contactsInfoToEdit?.email_address_items),
        );
      }
      this.clearFields();
    },
    editcontact() {
      if (this.contactsInfoToEdit) {
        this.contactsinfo = JSON.parse(JSON.stringify(this.contactsInfoToEdit));
        this.portal = this.getPortal(this.contactsinfo.contact_id);
        this.type = this.getType(this.contactsinfo.contact_id) || "";
      }

      // ADDRESS FIELDS
      if (this.contactsinfo.address_items == null) {
        this.addAddressField();
      } else {
        const rowCount =
          4 - (this.contactsinfo as Contact).address_items?.length;
        if (rowCount > 0 && rowCount < 4) this.addAddressField(rowCount);
      }
    },
    addAddressField(numberOfRows = 4) {
      const addressField = (this.contactsinfo as Contact).address_items;

      if (addressField?.length == null)
        (this.contactsinfo as Contact).address_items = [];

      let rowCount = 0;

      while (rowCount < numberOfRows) {
        (this.contactsinfo as Contact).address_items.push({
          address: "",
        });
        ++rowCount;
      }
    },
    getPortal(contact_id: any): boolean {
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      let contact_p: Customer["contact_id_items"] =
        map[ind].customer.contact_id_items;
      let portal = contact_p?.find(
        (e) => e.contact_id === contact_id,
      )?.contact_portal;
      return portal === "Y" ? true : false;
    },
    getType(contact_id: any): string | null | undefined {
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      let contact_t: Customer["contact_id_items"] =
        map[ind].customer.contact_id_items;
      return contact_t?.find((e) => e.contact_id === contact_id)?.contact_type;
    },
    deleteField(obj: any, index: number) {
      switch (Object.keys(obj)[0]) {
        case "email_address":
          (this.contactsinfo as Contact).email_address_items.splice(index, 1);
          break;
        case "telephone":
          (this.contactsinfo as Contact).telephone_items.splice(index, 1);
          break;
        default:
          break;
      }
    },
    pushEmailAddress() {
      this.isAddingField = true;

      if (this.emailAddress && /^\S+@\S+\.\S+$/.test(this.emailAddress)) {
        const emailItems = (this.contactsinfo as Contact).email_address_items;

        if (emailItems?.length == null)
          (this.contactsinfo as Contact).email_address_items = [];

        (this.contactsinfo as Contact).email_address_items?.push({
          email_address: this.emailAddress,
        });

        this.emailAddress = "";
      }
      this.isAddingField = false;
    },
    pushPhoneDetails() {
      this.isAddingField = true;

      if (this.telephone) {
        const telephoneItems = (this.contactsinfo as Contact).telephone_items;
        if (telephoneItems?.length == null)
          (this.contactsinfo as Contact).telephone_items = [];

        (this.contactsinfo as Contact).telephone_items.push({
          telephone: this.telephone,
          telephone_ext: this.telephoneExt,
        });

        this.telephoneExt = "";
        this.telephone = "";
      }

      this.isAddingField = false;
    },
    hideDialog(): void {
      this.$emit("onDialogClose");
    },
    clearFields() {
      this.contactsinfo = { contact_id: "" } as Contact;
      this.portal = false;
      this.type = "";
      this.emailAddress = "";
      this.telephone = "";
      this.telephoneExt = "";

      this.addAddressField();
    },
    saveCardInfo() {
      this.submitted = true;

      const contactObj = this.contactsinfo as Contact;

      // CHECK TO DISPLAY ERROR TO THE USER
      this.isAddingField = true;

      // perform basic input validation - empty/required values
      if (!contactObj.first_name?.trim()) {
        return (this.$refs.first_name as any).$el.focus();
      }
      if (this.emailAddress && !/^\S+@\S+\.\S+$/.test(this.emailAddress))
        return (this.$refs.email_address as any).$el.focus();
      if (
        this.telephone &&
        !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(this.telephone) &&
        !/^[0-9]{10}$/.test(this.telephone)
      )
        return (this.$refs.telephone as any).$el.focus();
      this.isAddingField = false;

      // UPON SUCCESSFUL VALIDATION ABOVE- ADD VALUES TO ARRAY
      this.pushPhoneDetails();
      this.pushEmailAddress();

      // if (!contactObj.email_address_items[0]?.email_address?.trim()) {
      //   return (this.$refs.email_address as any).$el.focus();
      // }

      // CLEAR EMPTY FIELDS FROM PAYLOAD
      if (
        contactObj.email_address_items !== null &&
        contactObj.email_address_items !== undefined
      ) {
        contactObj.email_address_items = contactObj.email_address_items.filter(
          (element) => element.email_address != "",
        );
      }
      // ...perform edit / save functions here
      let storedContact;
      if (
        this.contactsinfo.contact_id !== "" &&
        this.contactsinfo.contact_id !== null
      ) {
        storedContact = this.getContacts(this.index).contact_items.find(
          (contact: Contact) =>
            contact.contact_id === this.contactsinfo.contact_id,
        );
      }

      this.isSubmitting = true;
      let action;
      this.addContact({
        cust_id:
          this.getCustomer(this.index)?.cust_id || this.definedCustomerId,
        index: this.index,
        client: this.getClient,
        portal: this.portal ? "Y" : "",
        type: this.type,
        contact: this.contactsinfo,
        oldContact: storedContact || null,
        contact_id: this.contactsinfo.contact_id || null,
      })
        .then((response) => {
          this.isSubmitting = false;
          this.hideDialog();
          if (
            this.contactsinfo.contact_id === "" ||
            this.contactsinfo.contact_id === null
          ) {
            action = "NEW";
            this.contactsinfo.contact_id = response.recordId;
            if (this.contacts) {
              this.contacts = [...this.contacts, this.contactsinfo as Contact];
            } else {
              this.contacts = [this.contactsinfo as Contact];
            }
          } else {
            action = "EDIT";
            let index = this.contacts.findIndex(
              (contact: Contact) =>
                contact.contact_id === this.contactsinfo.contact_id,
            );
            if (index > -1) {
              this.contacts[index] = this.contactsinfo as Contact;
            } else {
              this.contacts = [...this.contacts, this.contactsinfo as Contact];
            }
          }
          if (this.getCustomer(this.index)?.cust_id) {
            this.updateContactFields({
              action,
              contacts: this.contacts,
              contactIdItems: {
                contact_id: this.contactsinfo.contact_id,
                type: this.type,
                portal: this.portal == true ? "Y" : "",
              },
              index: this.index,
            });
          }
          this.$emit("onContactSave", this.contactsinfo);
        })
        .catch(() => {
          this.isSubmitting = false;
          this.addNotification({
            message: "Error loading contacts",
            type: "error",
          });
        });
    },
  },
});
