import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vertical-align-top",
  style: {"vertical-align":"top"}
}
const _hoisted_2 = {
  key: 1,
  class: "vertical-align-top",
  style: {"vertical-align":"top"}
}
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      filters: _ctx.filters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
      globalFilterFields: Object.keys(_ctx.filters),
      filterDisplay: "menu",
      lazy: _ctx.lazyLoadPagination,
      value: _ctx.displayRecords,
      columns: _ctx.columns,
      totalRecords: _ctx.totalRecords,
      loading: _ctx.isLoading,
      responsiveLayout: "stack",
      paginator: "",
      selectionMode: "single",
      rows: _ctx.rowsPerPage,
      rowsPerPageOptions: [10, 25, 50],
      class: _normalizeClass(_ctx.tableClass),
      onSort: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sortData($event))),
      onFilter: _ctx.handleFilter,
      onPage: _ctx.onPage,
      onRowClick: _ctx.onRowClick,
      onRowDblclick: _ctx.onRowDoubleClick
    }, {
      empty: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "flex justify-content-center" }, [
          _createElementVNode("span", { class: "text-center" }, "No Results Found")
        ], -1)
      ])),
      default: _withCtx(() => [
        (_ctx.$slots.primary)
          ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
              body: _withCtx((slotProps) => [
                _renderSlot(_ctx.$slots, "primary", _normalizeProps(_guardReactiveProps({ data: slotProps.data })))
              ]),
              _: 3
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: column.dict_name,
            field: 'sort' + column.dict_name,
            header: column.display_name,
            headerClass: _ctx.columnHeaderClass,
            bodyStyle: _ctx.bodyStyle(column),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (_ctx.isDataArray(data[column.dict_name]))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.dict_name], (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item), 1))
                    }), 128))
                  ]))
                : (data[column.dict_name + '_items'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data[column.dict_name + '_items'], (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item[column.dict_name]), 1))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data[column.dict_name]), 1))
            ]),
            filter: _withCtx(({ filterModel }) => [
              (_ctx.isDictDateType(column))
                ? (_openBlock(), _createBlock(_component_Calendar, {
                    key: 0,
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    dateFormat: "mm-dd-yy",
                    class: "p-column-filter",
                    selectionMode: "range",
                    placeholder: `Select ${column.display_name} Date`
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                : (_openBlock(), _createBlock(_component_InputText, {
                    key: 1,
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    type: "text",
                    placeholder: `Enter ${column.display_name}`
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
            ]),
            _: 2
          }, 1032, ["field", "header", "headerClass", "bodyStyle"]))
        }), 128)),
        (_ctx.$slots.actions)
          ? (_openBlock(), _createBlock(_component_Column, { key: 1 }, {
              body: _withCtx((slotProps) => [
                _renderSlot(_ctx.$slots, "actions", _normalizeProps(_guardReactiveProps({ data: slotProps.data })))
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["filters", "globalFilterFields", "lazy", "value", "columns", "totalRecords", "loading", "rows", "class", "onFilter", "onPage", "onRowClick", "onRowDblclick"])
  ]))
}