
import { defineComponent, PropType, ref } from "vue";
import DataTable, { DataTableProps } from "primevue/datatable";
import Column from "primevue/column";
import Lookup from "@/types/lookup";
import LookupService, { LookupRequest } from "@/services/LookupService";
import Utils from "@/utility/utils";
import { Field, isDictDateType, isDictNumeric } from "@/types/fdict";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import { useLazyLoadPagination } from "@/composables/DataTable/useLazyLoadPagination";

const lookupService = new LookupService();

export default defineComponent({
  components: {
    DataTable,
    Calendar,
    Column,
    InputText,
  },
  props: {
    lookup: {
      type: Object as PropType<Lookup>,
      required: true,
    },
    params: {
      type: Object as PropType<any>,
      required: false,
    },
    lazyLoadPagination: {
      type: Boolean,
      default: false,
    },
    tableClass: {
      type: String,
      default: "p-datatable-sm w-12 text-sm bordered-datatable",
    },
    columnHeaderClass: {
      type: String,
      default: "",
    },
    getFullRecords: {
      type: Boolean,
      default: false,
    },
    manualLoad: {
      type: Boolean,
      default: false,
    },
    columnBodyStyle: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  emits: ["rowClick", "onLoad", "doubleClick"],
  setup(props, context) {
    const columns = ref<Field[]>([]);
    const filters = ref<any>({});
    const sorts = ref<any>({});
    const hasFilters = ref<boolean>(false);
    const pageRangeInterval = ref<number>(100);

    const fetchLookup = async (
      isReset = false,
      overrideRangeStart?: number,
      overrideRangeEnd?: number,
      params?: any,
    ) => {
      isLoading.value = true;
      var rangeStart = overrideRangeStart
        ? overrideRangeStart
        : records.value.length + 1;
      var rangeEnd = overrideRangeEnd
        ? overrideRangeEnd
        : first.value + pageRangeInterval.value;
      if (isReset) {
        rangeStart = overrideRangeStart ? overrideRangeStart : 1;
        rangeEnd = overrideRangeEnd
          ? overrideRangeEnd
          : pageRangeInterval.value;
        first.value = 0;
        records.value = [];
      }
      try {
        const lookupRequest = {
          lookupName: props.lookup.lookupName,
          Id: props.lookup.recordId,
          file: props.lookup.file,
          parameters: params ?? props.params,
          correls: props.lookup.correls,
          fullRecords: props.getFullRecords,
        } as Partial<LookupRequest>;

        if (props.lazyLoadPagination) {
          lookupRequest.rangeStart = "" + rangeStart;
          lookupRequest.rangeEnd = "" + rangeEnd;
        }

        const filterKeys = Object.keys(filters.value);
        if (filterKeys.length > 0) {
          filterKeys.forEach((key) => {
            if (filters.value[key].value) {
              if (!lookupRequest.filters) {
                lookupRequest.filters = {};
              }
              const column = columns.value.find(
                (column) => column.dict_name === key.substring(4),
              );
              if (column && isDictDateType(column)) {
                const dateRange = filters.value[key].value;
                dateRange[0] = Utils.formatDate(new Date(dateRange[0]));
                dateRange[1] = Utils.formatDate(new Date(dateRange[1]));
                lookupRequest.filters[key.substring(4)] = {
                  value: dateRange,
                  matchMode: filters.value[key].matchMode,
                };
              } else {
                lookupRequest.filters[key.substring(4)] = filters.value[key];
              }
            }
          });
        }

        const requestSorts = Object.keys(sorts.value);
        if (requestSorts.length > 0) {
          requestSorts.forEach((key) => {
            if (!lookupRequest.sorts) {
              lookupRequest.sorts = {};
            }
            lookupRequest.sorts[key.substring(4)] = sorts.value[key];
          });
        }
        const lookupData = await lookupService.fetchLookupData(lookupRequest);

        records.value.push(...lookupData.data);
        totalRecords.value = props.lazyLoadPagination
          ? +lookupData.total_records_found
          : lookupData.length;
        lookupData.fDicts.forEach((field: Field) => {
          if (!hasFilters.value) {
            columns.value.push({
              ...field,
              display_name: field.display_name
                ? field.display_name
                : Utils.formatDictionaryName(field.dict_name),
            });

            filters.value["sort" + field.dict_name] = {
              value: null,
              matchMode: isDictDateType(field)
                ? FilterMatchMode.BETWEEN
                : FilterMatchMode.CONTAINS,
            };
          }

          if (records.value.length > 0) {
            records.value = records.value.map((row) => {
              let sortVal = row[field.dict_name];
              if (isDictDateType(field)) {
                sortVal = sortVal ? new Date(sortVal) : "";
              }
              if (isDictNumeric(field)) {
                sortVal = sortVal ? +sortVal : 0;
              }
              return {
                ...row,
                ["sort" + field.dict_name]: sortVal,
              };
            });
          }
        });
        hasFilters.value = true;
        context.emit("onLoad", lookupData);
      } catch (error) {
        context.emit("onLoad", { status: "failed", error });
      } finally {
        isLoading.value = false;
      }
    };

    const { records, totalRecords, isLoading, first, rowsPerPage, onPage } =
      useLazyLoadPagination<any>(() => fetchLookup(), 10);

    return {
      columns,
      filters,
      records,
      totalRecords,
      isLoading,
      first,
      rowsPerPage,
      onPage,
      sorts,
      fetchLookup,
      hasFilters,
    };
  },
  async created() {
    if (!this.manualLoad) {
      await this.fetchLookup();
    }
  },
  computed: {
    displayRecords(): any[] {
      if (!this.lazyLoadPagination) {
        return this.records;
      }
      return this.records.slice(this.first, this.first + this.rowsPerPage);
    },
  },
  methods: {
    isDictNumeric(field: Field): boolean {
      return isDictNumeric(field);
    },
    isDictDateType(field: Field): boolean {
      return isDictDateType(field);
    },
    isDataArray(data: any): boolean {
      return Array.isArray(data);
    },
    async handleFilter(event: any) {
      if (this.lazyLoadPagination) {
        await this.fetchLookup(true);
      }
    },
    async sortData(event: any) {
      if (this.lazyLoadPagination) {
        this.sorts = {
          [event.sortField]: event.sortOrder === 1 ? "asc" : "desc",
        };
        await this.fetchLookup(true);
      }
    },
    bodyStyle(field: Field) {
      return {
        "text-align": isDictNumeric(field) ? "right" : "left",
        ...this.columnBodyStyle,
      };
    },
    onRowClick(event: any) {
      if (!event.data.id) {
        event.data.id = event.data[this.columns[0].dict_name];
      }
      this.$emit("rowClick", event.data);
    },
    onRowDoubleClick(event: any) {
      this.$emit("doubleClick", event.data);
    },
  },
});
