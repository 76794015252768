
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// Components
import AutoComplete from "primevue/autocomplete";

// Services
import LookupDataTable from "../Sales/LineItems/LookupDataTable.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Lookup from "@/types/lookup";
import ContactService from "@/services/ContactService";

const contactService = new ContactService(process.env.VUE_ABSTRACTION_API);

// Types
interface Data {
  query: string;
  suggestions: string[];
  showContactDialog: boolean;
  selectedContact: any;
}

export default defineComponent({
  components: {
    AutoComplete,
    LookupDataTable,
    Dialog,
    Button,
  },
  emits: ["item-select"],
  props: {
    selectedId: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customerContacts: {
      type: Array,
      required: false,
      default: () => [],
    },
    contactLookup: {
      type: String,
      required: false,
      default: "",
    },
    custId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data(): Data {
    return {
      query: "",
      suggestions: [],
      showContactDialog: false,
      selectedContact: null,
    };
  },
  watch: {
    selectedId() {
      this.getContactById();
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    showDropDown(): boolean {
      return this.customerContacts.length > 0;
    },
    contactsList(): any {
      const list = [...this.customerContacts, ...this.suggestions];
      return list.sort((a, b) => {
        const nameA = this.getFullname(a);
        const nameB = this.getFullname(b);
        if (nameA && nameB) {
          return nameA.localeCompare(nameB);
        } else {
          return 0;
        }
      });
    },
    getLookupParameters(): any {
      return {
        lookup: "",
      };
    },
    getLookup(): Lookup {
      const lookupData = this.contactLookup.split("*");
      return {
        key: this.contactLookup,
        file: lookupData[0],
        lookupName: lookupData[1],
        correls: "assoc_cust",
      } as Lookup;
    },
  },
  methods: {
    handleComplete(event: any) {
      // Call API for contact search terms
      if (this.query && this.contactLookup) {
        this.showContactDialog = true;
        this.$nextTick(() => {
          (this.$refs.contactLookupTable as any).fetchLookup(
            true,
            undefined,
            undefined,
            {
              lookup: event.query,
            },
          );
          (this.$refs.contactAutoComplete as any).searching = false;
        });
      } else if (this.query) {
        contactService
          .searchContacts(this.query, this.getClient)
          .then((response: any) => {
            this.suggestions = response.contact_items;
          })
          .finally(() => {
            (this.$refs.contactAutoComplete as any).searching = false;
          });
      } else {
        this.suggestions = [];
      }
    },
    handleEnter(event: any) {
      const search = this.query;
      event.enterKeyPressed = true;
      this.handleComplete({ query: search });
    },
    handleOnLoad(event: any) {
      // Leaving this here for future use
      // if (event.data.length === 1) {
      //   this.$emit("item-select", event.data[0]);
      //   this.showContactDialog = false;
      // }
    },
    handleItemSelect(event: any) {
      this.$emit("item-select", event.value);
    },
    handleDoubleClick(event: any) {
      this.$emit("item-select", event);
      this.showContactDialog = false;
    },
    handleRowClick(event: any) {
      this.selectedContact = event;
    },
    handleLookupSelect() {
      if (this.selectedContact) {
        this.$emit("item-select", this.selectedContact);
        this.showContactDialog = false;
      }
    },
    getFullname(contact: any) {
      let fullname = contact.first_name;
      if (contact.last_name) {
        fullname += " " + contact.last_name;
      }
      return fullname;
    },
    getContactById() {
      if (this.selectedId) {
        contactService
          .searchContacts(this.selectedId, this.getClient)
          .then((response: any) => {
            if (response.contact_items.length) {
              const contact = response.contact_items[0];
              this.query = contact.first_name;
              if (contact.last_name) {
                this.query += " " + contact.last_name;
              }
            }
          });
      } else {
        this.query = "";
      }
    },
  },
  mounted() {
    // Preset Selected Customer From Filter
    this.getContactById();
  },
});
